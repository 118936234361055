$primary-color: #1A7BEC;
$accent-color: #00dfb7;
$main-background: linear-gradient(135deg, $primary-color 0%, $accent-color 100%);

@import 'jekyll-theme-open-project';

.site-logo svg {
  path, circle {
    fill: white;
  }
}

body {
  > .underlay {
    > header {
      .site-logo {
        .symbol svg {
          height: 27px;
        }
        .title svg {
          position: relative;
          top: 3px;
          left: 3px;
        }
      }
      > nav {
        font-size: 16px;
        > * {
          font-weight: 700;
          margin-right: 2px;
          text-transform: lowercase;
        }
        .active {
          &:link, &:visited, &:hover {
            border-radius: 16px;
            padding-left: 1em;
            padding-right: 1em;
            background-color: white;
            color: $primary-dark-color;
          }
        }
      }
    }
  }
}

body.site--project.layout--home {
  .custom-intro {
    background: rgba(0, 0, 0, 0.4);
  }
  > .underlay.header {
    > .hero {
      a {
        color: #ffffff;
        text-decoration: underline;
      }
      img {
        float: left;
        height: 30px;
        margin-right: 10px;
      }
      > .text {
        > .cta .button {
          border-radius: 25px;
          padding-left: 32px;
          padding-right: 32px;
        }
      }
    }
  }
}
